import React from 'react';
import Marquee from 'react-fast-marquee';
import './Header.css';

function Header() {
  const logos = [
    '/assets/images/logo1.png',
    '/assets/images/logo2.png',
    '/assets/images/logo3.png',
    '/assets/images/logo4.png',
    '/assets/images/logo5.png',
    '/assets/images/logo6.png',
    '/assets/images/logo7.png',
    '/assets/images/logo8.png',
    '/assets/images/logo9.png',
    '/assets/images/logo10.png',
  ];

  return (
    <header className="header">
      <Marquee 
        speed={40} 
        pauseOnHover={false} 
        gradient={true} 
        gradientWidth={50}
        gradientColor={[0, 0, 0]} // Black gradient
      >
        {logos.map((logo, index) => (
          <img 
            key={index} 
            src={logo} 
            alt={`Logo ${index + 1}`} 
            className="marquee-logo"
          />
        ))}
      </Marquee>
    </header>
  );
}

export default Header;
