import React, { useState } from 'react';
import './Hero.css';

function Hero() {
  const [activeWindow, setActiveWindow] = useState(null);

  const handleToggleWindow = (window) => {
    setActiveWindow(activeWindow === window ? null : window);
  };

  return (
    <>
      <section className="hero">
        <div className="hero-content">
          {/* Availability Section */}
          <div className="availability-section">
            <div className="availability-header">
              <span>Next Availability</span>
            </div>
            <div className="top-bar"></div> {/* Top Bar added here */}
            <div className="availability-body">
              <p>Mar 14, 2025 - Apr 11, 2025</p>
            </div>
          </div>

          {/* Business Card Section */}
          <div className="business-card">
            {/* Left Section */}
            <div className="left-section">
              <img src="/assets/images/profile.jpg" alt="Profile" className="profile-pic" />
              <button
                className="action-button"
                onClick={() => handleToggleWindow('contact')}
              >
                Contact Me
              </button>
              <button
                className="action-button"
                onClick={() => handleToggleWindow('resume')}
              >
                Resume
              </button>
              <button
                className="action-button"
                onClick={() => handleToggleWindow('about')}
              >
                {activeWindow === 'about' ? 'Hide About Me' : 'About Me'}
              </button>
            </div>

            {/* Right Section */}
            <div className="right-section">
              <h1>Jon McIntosh</h1>
              <h2>Television Editor</h2>
              <p className="experience">17 Years Editing Experience</p>
              <p className="sub-experience">scripted | non-scripted | television | film</p>
            </div>
          </div>

          {/* Modal Sections */}
          {activeWindow === 'resume' && (
            <div className="modal-section modal-resume">
              <h3>Resume</h3>
              <iframe
                src="/assets/images/jon_mcintosh_resume.pdf"
                title="Jon McIntosh Resume"
                className="pdf-viewer"
              ></iframe>
              <div className="modal-buttons">
                <button
                  className="action-button"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = '/assets/images/jon_mcintosh_resume.csv';
                    link.download = 'jon_mcintosh_resume.csv';
                    link.click();
                  }}
                >
                  Download CSV
                </button>
                <button
                  className="action-button"
                  onClick={() => {
                    const link = document.createElement('a');
                    link.href = '/assets/images/jon_mcintosh_resume.pdf';
                    link.download = 'jon_mcintosh_resume.pdf';
                    link.click();
                  }}
                >
                  Download PDF
                </button>
              </div>
            </div>
          )}

          {activeWindow === 'contact' && (
            <div className="modal-section modal-contact">
              <h3>Contact Me</h3>
              <div className="contact-icons">
                <div className="contact-item">
                  <img src="/assets/images/mail.png" alt="Mail Icon" />
                  <button
                    className="action-button"
                    onClick={() => (window.location.href = 'mailto:jonnymacmedia@gmail.com')}
                  >
                    Email Me
                  </button>
                </div>
                <div className="contact-item">
                  <img src="/assets/images/telegram.png" alt="Telegram Icon" />
                  <button
                    className="action-button"
                    onClick={() => (window.location.href = 'https://t.me/jonnymacmedia')}
                  >
                    Telegram
                  </button>
                </div>
              </div>
            </div>
          )}

          {activeWindow === 'about' && (
            <div className="about-me-section">
              <h3>About Me</h3>
              <p>
                With 17 years of television editing experience, I've worked with many production companies to creatively tell stories
                under tight deadlines. Balancing creative vision and production notes is my forte, ensuring
                a polished product that satisfies both the client and audience.
              </p>
              <h3>Education</h3>
              <p>
                <strong>BA in Film and Television Production</strong> <br />
                Full Sail University, 2005
              </p>
              <h3>Skills</h3>
              <ul>
                <li>Avid Media Composer</li>
                <li>Adobe Premiere Pro</li>
                <li>Adobe After Effects</li>
                <li>Storytelling & Narrative Editing</li>
                <li>Collaborating with Producers & Directors from RC to FC</li>
                <li>Music Editing and Scoring</li>
                <li>Addressing Network Notes</li>
                <li>Sizzles and Pilots</li>
              </ul>
              <h3>Achievements</h3>
              <ul>
                <li>Editor for multiple Emmy-nominated projects</li>
                <li>Instrumental in developing many hit series like "60 Days In"</li>
                <li>Experienced in both scripted and non-scripted formats</li>
              </ul>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Hero;
