import React, { useState, useEffect } from "react";
import "./StickyMenu.css";
import { FaCalendarAlt, FaEnvelope, FaTelegramPlane } from "react-icons/fa";

const StickyMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [bookingMonth] = useState("March 14th"); // Manually set the booking month

  useEffect(() => {
    const handleScroll = () => {
      const heroBottom = document.querySelector(".hero")?.getBoundingClientRect().bottom || 0;
      setIsVisible(heroBottom <= 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`sticky-menu ${isVisible ? "visible" : ""}`}>
      <div className="sticky-content">
        {/* Availability Section */}
        <div className="availability">
          <FaCalendarAlt className="icon availability-icon" />
          <span>Now Booking for <strong>{bookingMonth}</strong></span>
        </div>

        {/* Branding Tagline (Only on Larger Screens) */}
        <div className="branding-tagline">
          <span>TV Editor | 17 Years Experience | Remote & On-Site</span>
        </div>

        {/* Contact Buttons */}
        <div className="contact-section">
          <a href="mailto:jonnymacmedia@gmail.com" className="contact-button">
            <FaEnvelope className="icon email-icon" />
            Email
          </a>
          <a href="https://t.me/jonnymacmedia" className="contact-button">
            <FaTelegramPlane className="icon telegram-icon" />
            Telegram
          </a>
        </div>
      </div>
      <div className="sticky-menu-line"></div> {/* Thin orange gradient line */}
    </div>
  );
};

export default StickyMenu;
