import React from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import StickyMenu from './components/StickyMenu';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <Header />
      <StickyMenu />
      <Hero />
      <Portfolio />
      <Footer />
    </div>
  );
}

export default App;
