import React, { useState } from 'react';
import './Portfolio.css';
import { FaThLarge, FaList } from 'react-icons/fa';

// Full portfolio data
const projects = [
  {
    title: 'Interrogation Raw: Celebrity Under Oath',
    year: 2025,
    type: 'Television',
    network: 'A&E Networks',
    description:
      'This special brings viewers inside the courtroom as celebrities are forced to sit in the hot seat and provide on-camera testimony.',
    thumbnail: '/assets/images/celeb.jpg',
  },
  {
    title: 'Interrogation Raw',
    year: 2024,
    type: 'Television',
    network: 'A&E Networks',
    description:
      'An all-new true crime series that explores the delicate twists and turns of fascinating interrogations.',
    thumbnail: '/assets/images/interrogation-raw.jpg',
  },
  {
    title: '60 Days In',
    year: 2023,
    type: 'Television',
    network: 'A&E Networks',
    description:
      'Follows participants who voluntarily go undercover in the Utah County Jail to shed light on drug and gang activity.',
    thumbnail: '/assets/images/60-days-in.jpg',
  },
  {
    title: 'Orca vs Great White',
    year: 2021,
    type: 'Television',
    network: 'National Geographic',
    description:
      'A deep dive into the mysterious attacks of orcas on great white sharks in South Africa.',
    thumbnail: '/assets/images/orca-vs-great-white.jpg',
  },
  {
    title: 'Baby Drivers',
    year: 2022,
    type: 'Television',
    network: 'Discovery+',
    description:
      'In the cutthroat world of motor sports, nothing is more intense than competitive go-karting.',
    thumbnail: '/assets/images/baby-drivers.jpg',
  },
  {
    title: 'Smoky Mountain Park Rangers',
    year: 2022,
    type: 'Television',
    network: 'National Geographic',
    description:
      'Park Rangers protect the wildlife in Great Smoky Mountain National Park.',
    thumbnail: '/assets/images/smoky.jpg',
  },
  {
    title: 'The Machines That Built America',
    year: 2021,
    type: 'Television',
    network: 'History Channel',
    description:
      'The stories behind innovations such as TV, radio, phones, airplanes, and power tools.',
    thumbnail: '/assets/images/machines-america.jpg',
  },
  {
    title: 'To Catch A Smuggler',
    year: 2020,
    type: 'Television',
    network: 'National Geographic',
    description:
      'DHS agents work to stop contraband smugglers at U.S. airports.',
    thumbnail: '/assets/images/smuggler.jpg',
  },
  {
    title: 'Family Reboot',
    year: 2019,
    type: 'Television',
    network: 'Disney+',
    description:
      'Families reconnect and rebuild their bonds in this heartwarming reality show.',
    thumbnail: '/assets/images/family-reboot.jpg',
  },
  {
    title: 'The Disappearance of Phoenix Coldon',
    year: 2018,
    type: 'Television',
    network: 'Oxygen',
    description:
      'Investigating the unsolved case of a young woman who vanished in 2011.',
    thumbnail: '/assets/images/phoenix-coldon.jpg',
  },
  {
    title: 'Behind Bars: Women Inside',
    year: 2018,
    type: 'Television',
    network: 'A&E Networks',
    description:
      'Exploring the reality of life inside a women’s correctional facility.',
    thumbnail: '/assets/images/women-inside.jpg',
  },
  {
    title: 'Behind Bars: Rookie Year',
    year: 2017,
    type: 'Television',
    network: 'A&E Networks',
    description:
      'Follows new recruits training at the Penitentiary of New Mexico.',
    thumbnail: '/assets/images/rookie-year.jpg',
  },
  {
    title: 'True Life',
    year: 2016,
    type: 'Television',
    network: 'MTV',
    description:
      'True Life: I have a dangerous eating obsession.',
    thumbnail: '/assets/images/true.jpg',
  },
  {
    title: 'Tough Enough',
    year: 2015,
    type: 'Television',
    network: 'USA Network',
    description:
      'Aspiring WWE superstars train for a chance to win a contract.',
    thumbnail: '/assets/images/tough-enough.jpg',
  },
  {
    title: 'In a Man’s World',
    year: 2018,
    type: 'Television',
    network: 'Bravo',
    description:
      'Women temporarily transform into men to explore gender dynamics.',
    thumbnail: '/assets/images/mans-world.jpg',
  },
  {
    title: 'Secrets of the Underground',
    year: 2017,
    type: 'Television',
    network: 'Science',
    description:
      'Scientists research fascinating underworlds of secret tunnel networks, ancient structures, and bizarre geological occurrences that offer glimpses into our history.',
    thumbnail: '/assets/images/secrets.jpg',
  },
  {
    title: 'Vinny & Ma Eat America',
    year: 2016,
    type: 'Television',
    network: 'Food Network',
    description:
      'Vinny Guadagnino travels across the U.S. with his mother, Paola Guadagnino, introducing her to cuisine other than Italian.',
    thumbnail: '/assets/images/vinny.jpg',
  },
  {
    title: 'Hogan Knows Best',
    year: 2005,
    type: 'Television',
    network: 'VH1',
    description:
      'Reality series documenting the life of Hulk Hogan and his family.',
    thumbnail: '/assets/images/hogan.jpg',
  },
  {
    title: 'Fix My Choir',
    year: 2014,
    type: 'Television',
    network: 'Oxygen',
    description:
      'Gospel superstars mentor struggling choirs to find their voice.',
    thumbnail: '/assets/images/choir.jpg',
  },
  {
    title: 'Cutting Crew',
    year: 2014,
    type: 'Television',
    network: 'El Rey Network',
    description:
      'Cutting Crew was an unscripted series about a barbershop community in Pennsylvania.',
    thumbnail: '/assets/images/crew.jpg',
  },
  {
    title: 'NCLR ALMA Awards',
    year: 2013,
    type: 'Television',
    network: 'MSNBC',
    description:
      'Award show honoring the accomplishments made by Hispanics in film, television, and music.',
    thumbnail: '/assets/images/alma.jpg',
  },
  {
    title: 'Wake Brothers',
    year: 2012,
    type: 'Television',
    network: 'MTV',
    description:
      'Follows both the personal and professional lives of wakeboarding sibling superstars.',
    thumbnail: '/assets/images/wake.jpg',
  },
  {
    title: 'Most Eligible Dallas',
    year: 2011,
    type: 'Television',
    network: 'Bravo',
    description:
      'Follows a group of successful friends, from beauty queens to pro-football players, living it up like only Texas socialites can.',
    thumbnail: '/assets/images/most.jpg',
  },
  {
    title: 'Miami Social',
    year: 2009,
    type: 'Television',
    network: 'Bravo',
    description:
      'Exploring the lives of young professionals navigating Miami’s vibrant social scene.',
    thumbnail: '/assets/images/miami-social.jpg',
  },
  {
    title: 'Never Back Down',
    year: 2008,
    type: 'Movie',
    network: 'Summit Entertainment',
    description:
      'Worked on computer graphics for the popular action movie *Never Back Down*, released in 2008.',
    thumbnail: '/assets/images/neverbackdown.jpg',
  },
];

function Portfolio() {
    const [view, setView] = useState('detailed'); // Default view is "detailed"
  
    const renderProjects = () => {
      if (view === 'list') {
        return (
          <div className="list-view">
            {projects.sort((a, b) => b.year - a.year).map((project, index) => (
              <div key={index} className="list-item">
                <img src={project.thumbnail} alt={project.title} className="thumbnail-list" />
                <div className="list-info">
                  <h3>{project.title}</h3>
                  <p className="network">
                    <span className="year">{project.year}</span> |{" "}
                    <span className="network-name">{project.network}</span>
                  </p>
                  <p className="description">{project.description}</p>
                </div>
              </div>
            ))}
          </div>
        );
      }
  
      return (
        <div className="detailed-view">
          {projects.sort((a, b) => b.year - a.year).map((project, index) => (
            <div key={index} className="project-card">
              <img src={project.thumbnail} alt={project.title} />
              <div className="project-details">
                <h3>{project.title}</h3>
                <p className="network">
                  <span className="year">{project.year}</span> |{" "}
                  <span className="network-name">{project.network}</span>
                </p>
                <p className="description">{project.description}</p>
              </div>
            </div>
          ))}
        </div>
      );
    };
  
    return (
      <section className="portfolio">
        <h2>Portfolio</h2>
        <div className="view-controls">
          <button onClick={() => setView('detailed')} title="Detailed View">
            <FaThLarge />
          </button>
          <button onClick={() => setView('list')} title="List View">
            <FaList />
          </button>
        </div>
        {renderProjects()}
      </section>
    );
  }
  
  export default Portfolio;
